/* The Modal (background) */
.modal {
    position: fixed; /* Stay in place */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(5, 97, 103); /* Fallback color */
    background-color: rgba(5, 97, 103, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
    background-color: var(--white);
    color: var(--green);
    margin: 15% auto; /* 15% from the top and centered */
    padding: var(--padding-element);
    /*
    border: 1px solid #888;
    */
    width: 80%; /* Could be more or less, depending on screen size */
    border-radius: var(--radius);

}

.modal-content .close + p {
    margin-top: 1.5em;
}

.image-wrapper {
    width: 100%;
    text-align: center;
}

/* The Close Button */
.close {
    color: var(--green);
    float: right;
    font-size: 3em;
    font-weight: bold;
    line-height: 0.5em;
}

.close:hover,
.close:focus {
    color: var(--green);
    text-decoration: none;
    cursor: pointer;
}

.img-modal{
    max-width: 80%;
    /*max-width: 70vw;*/
}
