
.nav-lng {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    top: 0;
    right: 0;
    padding: var(--padding-page);
}

.menu-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    align-self: center;
    margin: 0 calc(var(--padding-element) + 0.5em );
    padding: var(--padding-page);
}

.menu-container button {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    padding: 0.25em ;
    text-transform: uppercase;
    align-items: center;
    margin: 1em 0   0 0 ;
}
.nav-lng .content{
    width: 100vw;
}
.flag-icon{
    height: 2em;
    width: auto;
    margin-right: 1em;
}
/*


.menu-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.menu-container p{
    font-size: var(--font-size);
    color: var(--green);
    font-family: "Labil Grotesk", sans-serif;
    margin: 0
}
.menu {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    right: 0;
    visibility: hidden;
    transform: translateY(-10px);
    !*
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    *!
    margin-top: 14px;
}

.menu.active {
    opacity: 1;
    visibility: visible;

}

.menu {
    list-style: none;
    margin: 0;
    padding: 0;
    -webkit-box-shadow: -1px 4px 13px -1px rgba(0,0,0,0.45);
    box-shadow: -1px 4px 13px -1px rgba(0,0,0,0.45);
}

.menu  button {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: var(--dark);
    background: var(--purple);
    display: block;
    cursor: pointer;
    border: none;
    padding: var(--padding-element);
}
.menu button:hover{
    background: var(--green);

}
.menu-trigger {
    cursor: pointer;
    align-items: center;
    border: none;
    vertical-align: middle;
    transition: box-shadow 0.4s ease;
    background: none;
}

.menu-trigger button{
    font-size: var(--font-size);
}*/


/*

.menu-trigger span {
    font-weight: 700;
}

*/
