.overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 94, 100, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
.popup {
    position: absolute;
}

.popup .content {
    max-height: 30%;
}
/*input field*/
textarea{
    border: 1px var(--green) solid;
    border-radius: 32px;
    padding: var(--padding-element);
    width: calc(100% - (2 * var(--padding-page)) - var(--padding-element));

}
textarea:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

.input{
    display: flex;
    flex-direction: column;
}

.input button{
    margin: 0 var(--padding-element);
}
