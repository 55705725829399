/*imports*/

@font-face {
    font-family: "Labil Grotesk";
    src: local("LabilGrotesk"),
    url("./assets/fonts/LabilGrotesk/LabilGrotesk-Regular.woff") format("woff");
    font-weight: bold;
    /*font-feature-settings: "liga","salt";*/

}

@font-face {
    font-family: "Labil Grotesk Medium";
    src: local("LabilGrotesk"),
    url("./assets/fonts/LabilGrotesk/LabilGrotesk-Medium.woff") format("woff");
    font-weight: bold;
}

@font-face {
    font-family: 'LabilGroteskItalic';
    src: local('LabilGroteskItalic'),
    url('./assets/fonts/LabilGrotesk/LabilGrotesk-Regular.woff2') format('woff');
    font-weight: bold;
}

:root {

    /*color*/
    --dark: black;
    --white: white;
    --purple: #D8ADF7;
    --orange: #EF5502;
    --green: #005E64;
    --yellow: #E8F666;

    /*layout*/
    --padding-page: 0.75em;
    --padding-element: 1.75em;
    --height-icon-explanation: 4em;

    --radius: 70px;

    --font-size: 1em;
    --font-larger: 1.2em
}

.App {
    font-family: "Labil Grotesk", sans-serif;
    top: 0;
    min-height: 100vh;
    background: var(--purple);
    position: relative;

}

html, body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
}

/*font*/

p {
    font-size: var(--font-size);
}

a {
    font-size: var(--font-size);
}

button {
    font-size: var(--font-size);
}

.strong {
    font-family: "Labil Grotesk Medium";
}


h1 {
    font-size: 2em;
    margin-bottom: 0;
    font-feature-settings: "liga", "salt";

}

.container-start h1 {
    padding-top: 3em;
}

h2 {
    font-size: 2.8em;
}

.container-start h1 {
    color: var(--green);
}

/*layout classes*/
.space-between {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.vh-100 {
    min-height: calc(100vh - (2 * var(--padding-page)));
}

.pt-2 {
    padding-top: 2em;
}

.padding-page {
    padding: var(--padding-page);
}

.padding-element {
    padding-left: var(--padding-element);
    padding-right: var(--padding-element);
}

.right {
    text-align: right;
}

.align-center {
    display: flex;
    flex-direction: row;
    align-items: center
}

.relative {
    position: relative;
}

.not-sticky {
    position: revert;
}

.center {
    text-align: center;
    margin-bottom: 5rem;
}

.container {
    position: absolute;
    padding: var(--padding-page);
    /*z-index: 10;*/
    height: calc(100vh - 2 * (var(--padding-page)));
}

.container-end .card-explanation {
    margin: var(--padding-element) 0;
}

/*.container .card-explanation {*/
/*    margin:  var(--padding-element) 0;*/
/*}*/
.container-end {
    position: absolute;
    /*z-index: 10;*/
    padding: var(--padding-page);
}

.container-start {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 2 * (var(--padding-page)));
}

.hello {
    display: inline-block;
}

.container-search {
    position: absolute;
    z-index: 15;
    /*position: relative;*/
    /*display: flex;*/
    /*flex-direction: row;*/
    /*align-items: flex-end;*/
    /*padding: var(--padding-page);*/
    /*
    height: calc(100vh - 2 * (var(--padding-page)));
    */
    width: 100%;
    height: 100vh;

}

/*colors*/
.purple {
    color: var(--purple);
}

.green {
    color: var(--green);
}

/*buttons*/
.btn-start-ar {
    border-radius: 35px;
    font-size: var(--font-larger);
    text-transform: uppercase;
    text-decoration: none;
    color: var(--green);
    background: var(--yellow);
    margin: 0 2em 10em 2em;
}

.btn {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    background-blend-mode: multiply;
    box-sizing: border-box;
    padding: 0.75em 2em;
    border: none;
}

.btn-set {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--padding-page);
    bottom: 0;
    position: sticky;
}

.btn-set-popup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    bottom: 0;
    position: sticky;
}

.btn-set-right {
    justify-content: flex-end;
}

.btn-purple {
    background: var(--purple);
    border-radius: 79px;
    margin-bottom: 0.5em;
    color: var(--green);
    padding: 0.75em 2em;
}

.btn-purple-small {
    background: var(--purple);
    color: var(--green);
    border-radius: 79px;
    font-size: var(--font-larger);
    padding: 0.5em 2em;
    text-transform: uppercase;
    max-width: fit-content;
}

.btn-outline-white {
    border: 1px solid var(--white);
    border-radius: 151px;
    color: var(--white);
    text-decoration: none;
    background: var(--green);
}
.btn-outline-white-no-border {
    border-radius: 151px;
    color: var(--white);
    text-decoration: none;
    background: var(--green);
}

.btn-outline-green {
    border: 1px solid var(--green);
    border-radius: 151px;
    color: var(--green);
    background: var(--yellow);
    text-decoration: none;
}

.btn-filled {
    border-radius: 151px;
    color: var(--yellow);
    background: var(--green);
    text-decoration: none;
    font-weight: bold;
}

.btn-rating {
    border-radius: 90px;
    background: var(--purple);
    color: var(--green);
    text-transform: uppercase;
    font-size: 1em;
    margin-bottom: 5px;
    margin-top: 5px;
    text-align: center;
}

.btn-help {
    position: absolute;
    z-index: 100;
    right: 0;
    top: 0;
    margin: var(--padding-page);
    /*border: 1px solid var(--white);*/
    border-radius: 151px;
    color: var(--green);
    text-decoration: none;
    background: white;
}

.btn-white {
    background: var(--white);
    color: var(--green);
    border-radius: 151px;
    text-decoration: none;
    margin-bottom: 5px;
}

.btn-purple-green {
    background: var(--purple);
    color: var(--green);
    border-radius: 151px;
    text-decoration: none;
}

.btn-purple-white {
    background: var(--purple);
    color: white;
    border-radius: 151px;
    text-decoration: none;
    margin-bottom: 5px;
}

.btn-elevated {
    position: sticky;
    z-index: 5;
}

.btn-set-col {
    display: flex;
    flex-direction: column;
}

.green.btn-underline {
    color: white;
    border-radius: 90px;
    border: 1px solid white;
    margin-bottom: 5px;
    margin-top: 5px;
    text-align: center;
    padding: 0.75em 0.9em;
}

.yellow.btn-underline {
    color: var(--green);
    border-radius: 90px;
    border: 1px solid var(--green);
    margin-bottom: 5px;
    margin-top: 5px;
    text-align: center;
    padding: 0.75em 0.9em;
}

.btn-popup {
    font-size: 1em;
    text-transform: revert;
}

/*voting answer badges*/

.reply-badge {
    display: flex;
    flex-direction: column;
}

.reply-badge span {
    align-self: end;
}

.badges {
    display: flex;
    flex-direction: column;
    z-index: 1;
    /*
    margin-top: calc(var(--padding-page) * 2);
    */
    align-items: stretch;
    margin: var(--padding-page);
}

.veryPositive {
    background: var(--yellow);
    color: var(--green);
}

.positive {
    background: var(--purple);
    color: var(--green);
}

.negative {
    background: white;
    color: var(--green);
}

.veryNegative {
    background: var(--orange);
    color: white;
}

/*about section*/
.wrapper-label {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}


/*card with role description & explanation texts*/

.bg-green .card-explanation {
    background: var(--yellow);
}

.bg-green .card-explanation h1 {
    color: var(--green);
    margin: 0;
}

.bg-green .card-explanation p {
    color: var(--green);
}

.bg-yellow .card-explanation {
    background: var(--green);
}

.bg-yellow .card-explanation h1 {
    color: white;
    margin: 0;
}

.bg-yellow .card-explanation p {
    color: white;
    line-height: 1.4;
}

.popup p {
    margin-bottom: 1em;
}

.card-explanation {
    padding: 1em 1.75em 1em 1.75em;
    margin: var(--padding-page);
    border-radius: var(--radius);
    z-index: 0;
}

.card-explanation textarea {
    margin-bottom: .7em;
}

textarea::-webkit-input-placeholder {
    color: var(--green);
}

textarea:-moz-placeholder { /* Firefox 18- */
    color: var(--green);
}

textarea::-moz-placeholder { /* Firefox 19+ */
    color: var(--green);
}

textarea:-ms-input-placeholder {
    color: var(--green);
}

textarea::placeholder {
    color: var(--green);
}


.text-input-field {
    font-family: "Labil Grotesk", sans-serif;
    font-size: var(--font-size);
    color: var(--green) !important;
    border: none;
}

.action-style {
    padding: var(--padding-page);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/*role image*/

.img-role {
    padding: var(--padding-element);
    right: 0;
    top: 0;
    align-self: end;
    position: sticky;
    height: var(--padding-element);
    text-align: right;
}

.img-role-small img {
    height: 6em;
    width: auto;
    float: right;
}

.img-role-small {
    padding-top: 0;
    padding-bottom: 0;
}

/*page background color*/
/********************************/
/*.container {
    padding: var(--padding-page);
    height: calc(100vh - 2 * (var(--padding-page)));
}*/
/*

.container-start {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
*/

.bg-green {
    background: var(--green);
    color: var(--white);
}

.bg-green h2 {
    color: var(--white);
}

.bg-yellow {
    background: var(--yellow);
    color: var(--green);
}

.bg-yellow {
    background: var(--yellow);
    color: var(--green);
}

.bg-white {
    background: var(--white);
}

.bg-orange {
    background: var(--orange)
}

/*language dropdown*/


/*AR part*/

.example-container {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
}

#example-scanning-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: transparent;
    z-index: 2;
}

@media (min-aspect-ratio: 1/1) {
    #example-scanning-overlay .inner {
        width: 50vh;
        height: 50vh;
    }
}

@media (max-aspect-ratio: 1/1) {
    #example-scanning-overlay .inner {
        width: 80vw;
        height: 80vw;
    }
}

#example-scanning-overlay .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    background: linear-gradient(to right, white 10px, transparent 10px) 0 0,
    linear-gradient(to right, white 10px, transparent 10px) 0 100%,
    linear-gradient(to left, white 10px, transparent 10px) 100% 0,
    linear-gradient(to left, white 10px, transparent 10px) 100% 100%,
    linear-gradient(to bottom, white 10px, transparent 10px) 0 0,
    linear-gradient(to bottom, white 10px, transparent 10px) 100% 0,
    linear-gradient(to top, white 10px, transparent 10px) 0 100%,
    linear-gradient(to top, white 10px, transparent 10px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 40px 40px;
}

#example-scanning-overlay.hidden {
    display: none;
}

#example-scanning-overlay img {
    opacity: 0.6;
    width: 90%;
    align-self: center;
}

#example-scanning-overlay .inner .scanline {
    position: absolute;
    width: 100%;
    height: 10px;
    background: white;
    animation: move 2s linear infinite;
}

@keyframes move {
    0%, 100% {
        top: 0%
    }
    50% {
        top: calc(100% - 10px)
    }
}

/*asterix*/
.asterix {
    color: var(--white);
    position: absolute;
    padding: 0;
}

.asterix img {
    height: 40vh;
    vertical-align: bottom;
}

.asterix p {
    padding: 0;
    margin: 0;
}

.z-index {
    z-index: 100;
}

#asterix-1 {
    right: 0;
    top: 3vh;
}

#asterix-2 {
    bottom: 0;
    right: 0;
}

#asterix-3 {
    left: 0;
    top: 30vh;
}

@media screen and (min-width: 580px) {
    .asterix img {
        height: 50vh;
    }
}

/*not found*/
.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--padding-page);

}

.not-found h2 {
    color: var(--green);
}

/*navigation AR*/

.navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: var(--yellow);
    color: var(--green);
    border-radius: var(--radius);
    align-items: center;
    margin: var(--padding-page);
    /*margin-bottom: 1rem;*/
    padding: var(--padding-element);
}

.navigation h3 {
    margin: 0;
    padding: 0 0.5em;
}

.arrLeft {
    transform: scaleX(-1);
}

/*responsive*/
@media screen and (max-width: 450px) {
    h2 {
        font-size: 2em;
    }
}

@media screen and (max-height: 670px) {

    h3 {
        font-size: 1.02em;
        margin-block-start: 0.2em;
        margin-block-end: 0.2em;
    }

    .card-explanation {
        padding: 0.8em 1.6em 0.8em 1.6em;
        margin: var(--padding-page);
        border-radius: var(--radius);
        z-index: 0;
    }

    .instruction-box {
        position: absolute;
        top: 4rem;
    }

    .navigation {
        padding: 1em;
    }

    .klimaball {
        margin-top: 0;
    }

    .intro.space-between {
        justify-content: flex-start;
    }

}

.arrow h2 {
    margin: 0 !important;
    font-weight: bold;
}

.intro-text {
    position: initial;
    z-index: 100;
    background: var(--purple);
    color: var(--green);
    border-radius: var(--radius);
    text-align: left;
}

.intro-text p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.klimaball {
    margin-top: 1rem;
}

.klimaball-img {
    opacity: 0.5;
    width: 100%;
}

.scanner-img {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    max-width: 80vw;
}

.instruction-box {
    position: absolute;
    top: 6rem;
    text-align: center;
    border-radius: var(--radius);
    width: 80vw;
    color: white;
    background: var(--green);
    opacity: 0.8;
}

.instruction-box-wrapper {
    top: -10rem;
    display: flex;
    justify-content: center;
}

.instruction-box h3 {
    font-family: "Labil Grotesk", sans-serif;
    font-size: var(--font-size);
    margin: 1em;
}

/*Evaluation*/
.uppercase {
    margin-top: 0.4em;
    margin-bottom: 0;
    font-weight: bold;
    font-family: "Labil Grotesk Medium", sans-serif;
    margin-right: 10px;
    hyphens: auto;
    word-break: break-word;
    word-wrap: break-word;
    margin-right: 3px;
}

.uppercase:hover {
    cursor: pointer;
}

.location-filter {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2em;
}

.location-filter p{
    hyphens: auto;
}

.legend-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2em;
    margin-bottom: 2em;
}

.legend-box p {
    padding-right: 1em;
    display: flex;
}

.legend-box span:before {
    content: "";
    width: 1em;
    height: 1em;
    display: inline-block;
    margin-right: 1em;
}

.legend-box span {
    margin-right: 0.5em;
}

.section-evaluation h3 {
    text-transform: uppercase;
}

.section-evaluation {
    margin-bottom: 3em;
}

.stacked-chart-container {
    width: 80%;
    display: flex;
}

.stacked-chart-container span {
    text-align: center;
}

.container-evaluation {
    position: relative;
    padding: var(--padding-page);
    z-index: 10;
    padding-bottom: 3em;
    /* height: calc(100vh - 2 * (var(--padding-page))); */
}

@media screen and (max-height: 991px) {

    .hidden-on-mobile {
        display: none;
        width: 0;
    }

    .stacked-chart-container {
        width: 100%;
    }
    .action-selector {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
    }
    .section-evaluation{
        width: 100%;
    }
    .svg-container {
        width: 80%;
        height: auto;
        resize: both;
        overflow: auto;
        padding-bottom: var(--padding-element);
    }
    .svg-container-website {
        width: 80%;
        height: auto;
        resize: both;
        overflow: auto;
        padding-bottom: var(--padding-element);
        flex-grow: 0;
        flex-shrink: 1;
    }

    .svg-container svg {
        width: 95%;
        height: 95%;
    }
    .svg-container-website svg {
        width: 95%;
        height: 95%;
    }
}

@media screen and (min-width: 992px) {
    .hidden-on-desktop {
        display: none;
    }
    .section-evaluation{
        width: 45vw;
    }
    .action-selector {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        position: relative;
        padding-left: 50%
    }
    .svg-container {
        width: auto;
        height: 60vh;
        resize: both;
        overflow: auto;
        position: fixed;
        left: 0;
        bottom: 0;
        margin-left: 8%;
    }
    .svg-container-website {
        width: 40vw;
        resize: both;
        overflow: auto;
        position: absolute;
        left: 0;
        margin-left: 8%;
        height: auto;
        top: 0;
        flex-grow: 0;
        flex-shrink: 1;

    }

    .svg-container svg {
        width: 95%;
        height: 95%;

    }
    .svg-container path:hover{
        fill: var(--purple);
    }

    .svg-container path:active{
        fill: var(--purple);
    }
    .svg-container-website svg {
        width: 95%;
        height: 95%;
    }
    .svg-container-website path:hover{
        fill: var(--purple);
    }

    .svg-container-website path:active{
        fill: var(--purple);
    }
    .svg-text text{
        color: var(--green);
        font-size: var(--font-size);
        z-index: 500;
        height: 3em;
    }

    svg text{
        text-anchor: middle;
        dominant-baseline: middle;
    }

    .hidden-on-mobile {
        display: inline-block;
    }

    .stacked-chart-container {
        flex: 1;

        width: 100%;
    }

    .bar-chart-label {

        width: 20%;
        margin-left: var(--padding-element);
    }

    .bar-chart-label p {
        margin: 0;
    }
}

.stacked-chart-wrapper {
    display: flex;
    flex-direction: row;
}

.btn-evaluation {
    position: fixed;
    z-index: 20;
    bottom: 0;
    margin: var(--padding-element);
}

.evaluation h1 {
    margin: 0;
    padding: var(--padding-page)

}

.bold{
    font-weight: bold;
    font-family: "Labil Grotesk Medium", sans-serif;
}

.text-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.dot{
    padding-right: 0.5em;
    margin:0;
}

/*gamification*/
.bottom-sheet {
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--white);
    border-radius: calc(var(--radius) - 27px) calc(var(--radius) - 27px) 0 0;
    padding-bottom: var(--padding-page);
    padding-top: 0.5em;
    margin: 0 var(--padding-page) ;

}
.bottom-sheet div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

}
.score {
}

.bottom-sheet p{
    margin: 0.1em;
    color: var(--green);
    text-align: center;
}
.bottom-sheet div img {
    height: 1.25em;
    width: auto;
    padding-left: 0.25em;
}
